import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import isNull from 'lodash/isNull';
import clsx from 'clsx';
import { GatsbyImage } from 'gatsby-plugin-image';

import { styleConfig } from '../../config/config';

import MuiButton from './MuiButton';
import Title from './Common/Title';
import RouteLink from './RouteLink';

const ContentModuleLargeLinks: React.FunctionComponent<ContentModuleLargeLinksProps> = (props) => {
  const { items } = props;

  const firstItem = items && items[0];
  const isMulti = items && items.length >= 1;

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      minHeight: 400,

      [theme.breakpoints.up('md')]: {
        minHeight: 'inherit',
      },
    },
    noPadding: {
      paddingLeft: '0!important',
      paddingRight: '0!important',
    },
    cta: {
      position: 'relative',
      overflow: 'hidden',
      background: styleConfig.color.bg.black,
      height: 800,

      [theme.breakpoints.down('md')]: {
        height: 400,
      },
    },
    vc: {
      position: 'relative',
      top: 'auto',
      transform: 'none',
      width: '100%',
      zIndex: 5,

      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        top: '50%',
        transform: 'translate3d(0, -50%, 0)',
      },
    },
    image: {
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      display: 'block',
      height: '100%',
      width: '100%',
      overflow: 'hidden',

      '&:before, &:after': {
        content: `''`,
        position: 'absolute',
        zIndex: 1,
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        display: 'block',
      },

      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center center',
        opacity: isMulti ? 0.5 : 0.6,
      },
    },
    withEffect: {
      position: 'relative',

      '&:before': {
        zIndex: 2,
        backgroundImage:
          'linear-gradient(-39deg, rgba(198,161,92,0.00) 50%, rgba(198,161,92,0.80) 100%)',
        opacity: 0.8,
      },
      '&:after': {
        backgroundImage: 'linear-gradient(206deg, rgba(0,0,0,0.00) 5%, rgba(0,0,0,0.75) 100%);',
        opacity: 1,
      },
    },
    inner: {
      position: 'relative',
      textAlign: 'left',
      padding: 50,

      [theme.breakpoints.up('md')]: {
        display: 'block',
      },

      [theme.breakpoints.down('sm')]: {
        padding: 25,
      },

      '& h3': {
        textTransform: 'uppercase',
        marginBottom: '4rem',
      },

      '& h4': {
        textTransform: 'uppercase',
        marginBottom: '4rem',
      },
    },

    multiCta: {
      width: '100%',
      position: 'relative',
      overflow: 'hidden',
      background: styleConfig.color.bg.black,
      height: 800,

      [theme.breakpoints.down('md')]: {
        height: 400,
      },
    },

    multiCtaItem: {
      width: '100%',
      overflow: 'hidden',
      position: 'relative',
      display: 'block',
      height: 800,

      [theme.breakpoints.down('md')]: {
        height: 400,
      },
    },
  }));

  const classes = useStyles();
  return items && items.length === 3 ? (
    <Grid container>
      {firstItem && (
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.multiCta}>
          <Box className={classes.vc}>
            <Grid container justifyContent="center">
              <Grid item sm={10} className={classes.inner}>
                {!isNull(firstItem.title) && !isNull(firstItem.pre_title) && firstItem.title && (
                  <Title
                    isSubtitle
                    subtitleText={firstItem.pre_title}
                    subtitleType="subtitle1"
                    titleColor="white"
                    titleHeavy
                    titleSize="h3"
                    titleText={firstItem.title}
                  />
                )}

                {firstItem.link && (
                  <RouteLink href={`/${firstItem.link.url}/`}>
                    <MuiButton
                      aria-label={firstItem.link.title}
                      name="large-links-link-primary"
                      variant={firstItem.type === 'block' ? 'contained' : 'outlined'}
                    >
                      <div dangerouslySetInnerHTML={{ __html: firstItem.link.title }} />
                    </MuiButton>
                  </RouteLink>
                )}
              </Grid>
            </Grid>
          </Box>

          {firstItem.image && firstItem.image.localFile && (
            <GatsbyImage
              image={firstItem.image.localFile.childImageSharp.gatsbyImageData}
              className={clsx(firstItem.overlay && classes.withEffect, classes.image)}
              alt={firstItem.image.alt_text || 'Large Image'}
              loading="lazy"
            />
          )}
        </Grid>
      )}

      <Grid item sm={12} md={6}>
        <Box className={classes.multiCta}>
          {items &&
            items.map(
              (block, index) =>
                // Skip the first item since it is used above
                index >= 1 && (
                  <div key={index} className={classes.multiCtaItem}>
                    <div className={classes.vc}>
                      <Grid container justifyContent="center">
                        <Grid item sm={10} className={classes.inner}>
                          {block.title && (
                            <Title
                              isSubtitle
                              subtitleText={block.pre_title}
                              subtitleType="subtitle1"
                              titleColor="white"
                              titleHeavy
                              titleSize="h3"
                              titleText={block.title}
                            />
                          )}

                          {block.link && (
                            <RouteLink href={`/${block.link.url}/`}>
                              <MuiButton
                                aria-label={block.link.title}
                                labelColor="#FFF"
                                name={`large-links-button-group-${index}`}
                                variant={block.type === 'block' ? 'contained' : 'outlined'}
                              >
                                <div dangerouslySetInnerHTML={{ __html: block.link.title }} />
                              </MuiButton>
                            </RouteLink>
                          )}
                        </Grid>
                      </Grid>
                    </div>

                    {block.image && block.image.localFile && (
                      <GatsbyImage
                        image={block.image.localFile.childImageSharp.gatsbyImageData}
                        className={clsx(block.overlay && classes.withEffect, classes.image)}
                        alt={block.image.alt_text || 'Large Image'}
                        loading="lazy"
                      />
                    )}
                  </div>
                )
            )}
        </Box>
      </Grid>
    </Grid>
  ) : (
    <Grid container>
      {firstItem && (
        <Grid item sm={12} md={12}>
          <div className={classes.multiCta}>
            <div className={classes.vc}>
              <Grid container justifyContent="center">
                <Grid item sm={10} className={classes.inner}>
                  {firstItem.title && (
                    <Title
                      isSubtitle
                      subtitleText={firstItem.pre_title}
                      subtitleType="subtitle1"
                      titleColor="white"
                      titleHeavy
                      titleSize="h3"
                      titleText={firstItem.title}
                    />
                  )}

                  {firstItem.link && (
                    <RouteLink href={`/${firstItem.link.url}/`}>
                      <MuiButton
                        aria-label={firstItem.link.title}
                        name="large-links-item-primary"
                        variant="contained"
                      >
                        <div dangerouslySetInnerHTML={{ __html: firstItem.link.title }} />
                      </MuiButton>
                    </RouteLink>
                  )}
                </Grid>
              </Grid>
            </div>

            {firstItem.image && firstItem.image.localFile && (
              <>
                <GatsbyImage
                  image={firstItem.image.localFile.childImageSharp.gatsbyImageData}
                  className={clsx(firstItem.overlay && classes.withEffect, classes.image)}
                  alt={firstItem.image.alt_text || 'Large Image'}
                  loading="lazy"
                />
              </>
            )}
          </div>
        </Grid>
      )}
    </Grid>
  );
};

interface ContentModuleLargeLinksProps {
  items?: {
    type: string;
    overlay: boolean;
    image: {
      alt_text?: string;
      localFile: {
        childImageSharp: any;
      };
    };
    link: {
      title: string;
      url: string;
    };
    title: string;
    pre_title: string;
  }[];
}

export default ContentModuleLargeLinks;
